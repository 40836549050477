import { historyList } from '../../tempData';

export const History = () => {
	return (
		<div className="table-container">
			<table className="table-style">
				<colgroup>
					<col width="150px" />
					<col width="50px" />
					<col width="100px" />
					<col width="150px" />
					<col width="150px" />
					<col width="100px" />
					<col width="100px" />
				</colgroup>
				<thead>
					<tr>
						<th className="first">
							<div className="chkbox-con">
								<input type="checkbox" />
								<label type="checkbox">Order ID</label>
							</div>
						</th>
						<th>유저 아이디</th>
						<th>날짜</th>
						<th className="center">TYPE</th>
						<th className="center">Payment Status</th>
						<th>Total</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{historyList.map((i) => {
						return (
							<tr>
								<td>
									<div className="d-flex y-center">
										<div className="type-icon">
											{i.type === 'green' && (
												<img
													src={
														require('../../assets/img/green.svg')
															.default
													}
													alt="green"
												/>
											)}

											{i.type === 'yellow' && (
												<img
													src={
														require('../../assets/img/yellow.svg')
															.default
													}
													alt="yellow"
												/>
											)}

											{i.type === 'red' && (
												<img
													src={
														require('../../assets/img/red.svg').default
													}
													alt="red"
												/>
											)}
										</div>

										<p>#5036</p>
									</div>
								</td>

								<td>
									<p>{i.id}</p>
								</td>
								<td className="gray">{i.date}</td>
								<td className="center">
									<div className={`type-container ${i.chainType}`}>
										<p className="type-text">
											{i.chainType === 'on' && 'ON-chain'}
											{i.chainType === 'off' && 'OFF-chain'}
											{i.chainType === 'full' && 'ON/OFF'}
										</p>
									</div>
								</td>
								<td className="center">{i.paymentStatus}</td>
								<td>{i.total}</td>
								<td className="pointer">
									<img
										src={
											require('../../assets/img/ellipsis-vertical-outline.svg')
												.default
										}
										alt="dot"
									/>
								</td>
							</tr>
						);
					})}
					<tr></tr>
				</tbody>
			</table>
		</div>
	);
};
