import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { useState } from 'react';
import { useNoticeHook } from '../../hook/useNoticeHook';
import { useCallback } from 'react';
import { useEffect } from 'react';

export const NoticeDetailModal = ({
	visible,
	setVisible,
	getNoticeListFn,
	clickId,
	setClickId,
}) => {
	const { updateNotice, getNoticeDetail, deleteNotice } = useNoticeHook();
	const [content, setContent] = useState('');
	const [subject, setSubject] = useState('');
	const [isImportant, setIsImportant] = useState(false);

	const getDetail = useCallback(async () => {
		const item = await getNoticeDetail({ clickId });
		setSubject(item.subject);
		setContent(item.content);
		setIsImportant(item.isImportant);
	}, [clickId]);

	useEffect(() => {
		if (clickId !== -1) getDetail();
	}, [clickId, visible]);

	const update = async () => {
		try {
			await updateNotice({ content, subject, id: clickId, isImportant });
			alert('수정 되었습니다.');
		} catch (err) {
			alert('에러가 발생 했습니다.');
		}
		await getNoticeListFn();
		close();
	};

	const close = () => {
		setVisible(false);
	};

	const deleteNoticeItem = async () => {
		await deleteNotice({ id: clickId });
		await getNoticeListFn();
		setClickId(-1);
		close();
	};

	if (!visible) return <></>;
	return (
		<div className="modal-wrap">
			<div className="dim" />
			<div className="modal-container">
				<div className="modal-header">
					<p className="modal-title">수정 및 삭제</p>
					<div className="header-close">
						<div onClick={deleteNoticeItem} style={{ marginRight: 10 }}>
							<img
								src={require('../../assets/img/trash-outline.svg').default}
								alt="pen"
							/>
						</div>
						<div onClick={close}>
							<img
								src={require('../../assets/img/close-outline.svg').default}
								alt="pen"
							/>
						</div>
					</div>
				</div>

				<div className="modal-content">
					<div className="modal-account-container">
						<p className="acconut-title">Title</p>
						<input
							value={subject}
							type="text"
							className="input-style1 mr20"
							placeholder="Title"
							onChange={(e) => {
								setSubject(e.target.value);
							}}
						/>
						<div
							className="d-flex y-center shrink0"
							onClick={() => setIsImportant(!isImportant)}
						>
							<p className="mr10">중요</p>
							<div className="chkbox-con">
								<input type="checkbox" checked={isImportant} />
								<label type="checkbox"></label>
							</div>
						</div>
					</div>
					<ReactQuill theme="snow" value={content} onChange={setContent} height={300} />
				</div>

				<div className="modal-bottom">
					<div className="cancel-button" onClick={close}>
						<img
							src={require('../../assets/img/close-outline-black.svg').default}
							alt="x"
						/>
						<p>Cancel</p>
					</div>
					<div className="button-container" style={{ width: 153 }} onClick={update}>
						<input type="button" value={'Save'} className="button-type2" />
					</div>
				</div>
			</div>
		</div>
	);
};
