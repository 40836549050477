import { useState } from 'react';

export const Checkbox = ({ text, check, setCheck }) => {
	return (
		<div className={`checkbox-wrap ${check && 'check'}`} onClick={() => setCheck(!check)}>
			<div className={`checkbox-icon`}>
				{check ? (
					<img
						src={require('../../assets/img/checkmark-outline-active.svg').default}
						alt={'check'}
					/>
				) : (
					<img
						src={require('../../assets/img/checkmark-outline.svg').default}
						alt={'check'}
					/>
				)}
			</div>

			<p className="checkbox-text">{text}</p>
		</div>
	);
};
