import api from '../api';

export const useContactHook = () => {
	const getContactList = async ({ page, take }) => {
		try {
			const { data } = await api.get(`/api/admin/contact?page=${page}&take=${take}`);
			return data;
		} catch (err) {
			console.log(err);
		}
	};

	const getContactDetail = async ({ clickId }) => {
		try {
			const { data } = await api.get(`/api/admin/contact/read/${clickId}`);
			return data;
		} catch (err) {
			console.log(err);
		}
	};

	const createContactAnswer = async ({ subject, content, id }) => {
		try {
			const { data } = await api.post('/api/admin/contact', {
				subject,
				content,
				contactId: id,
			});
			return data;
		} catch (err) {
			console.log(err);
		}
	};

	const updateContactAnswer = async ({ subject, content, id }) => {
		try {
			const { data } = await api.patch('/api/admin/contact/answer', {
				subject,
				content,
				id,
			});
			return data;
		} catch (err) {
			console.log(err);
		}
	};

	return { getContactList, getContactDetail, createContactAnswer, updateContactAnswer };
};
