import { useState } from 'react';

export const DropDown = () => {
	const [active, setActive] = useState(false);
	const [activeItem, setActiveItem] = useState({ label: '제목', value: 'title' });

	return (
		<div className="dropdown-container" onClick={() => setActive(!active)}>
			<div className="dropdown">
				<div>
					<p className="active">{activeItem.label}</p>
				</div>
			</div>

			{active && (
				<div className="menu-container">
					<div
						className="menu"
						onClick={() => {
							setActiveItem({ label: '제목', value: 'title' });
						}}
					>
						<p>제목</p>
					</div>
					<div
						className="menu"
						onClick={() => {
							setActiveItem({ label: '계정', value: 'account' });
						}}
					>
						<p>계정</p>
					</div>
				</div>
			)}
		</div>
	);
};
