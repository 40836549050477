import dayjs from 'dayjs';

export const NoticeItem = ({ item, setClickId, setVisibleDetailModal }) => {
	return (
		<div
			className="notice-item"
			onClick={() => {
				setVisibleDetailModal(true);
				setClickId(item.id);
			}}
		>
			<div className={`key ${!item.isImportant ? 'off' : ''}`}>
				<img src={require('../../assets/img/pin.svg').default} alt="key" />
			</div>
			<div className="notice-title">
				<div className="notice-icon">
					<img src={require('../../assets/img/reader.svg').default} alt={'reader'} />
				</div>
				<div className="notice-text">
					<p>{item.subject}</p>
				</div>
			</div>

			<div className="notice-desc">
				<div dangerouslySetInnerHTML={{ __html: item.content }}></div>
			</div>

			<div className="date">
				<p> {dayjs(item.updateAt).format('YYYY-MM-DD HH:mm')}</p>
			</div>
		</div>
	);
};
