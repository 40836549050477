import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { DropDown } from '../../components/common/DropDown';
import { SelectFilter } from '../../components/common/SelectFilter';
import { UserList } from '../../components/user/UserList';
import { dashboardFilter } from '../../tempData';

export const User = () => {
	const [currentFilter, setCurrentFilter] = useState({ label: 'on-chain', value: 'on' });

	return (
		<>
			<div className="user">
				<div className="search-container no-margin">
					<h3>유저리스트</h3>
					<div className="search-wrap">
						<div className="search-input-container">
							<input
								type="text"
								className="input-style2"
								placeholder="Search Notes"
							/>
							<div className="search-icon">
								<img
									src={require('../../assets/img/search-outline.svg').default}
									alt="search"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex x-eq mb30 mt30">
					<div className="">
						<SelectFilter
							filterList={dashboardFilter}
							currentFilter={currentFilter}
							setCurrentFilter={setCurrentFilter}
						/>
					</div>

					<div className="d-flex">
						<div className="mr10">
							<DropDown />
						</div>
						<DropDown />
					</div>
				</div>

				<div className="mb30">
					<UserList />
				</div>

				<div className="paging-container">
					<ReactPaginate
						pageCount={100}
						breakLabel="..."
						pageRangeDisplayed={5}
						renderOnZeroPageCount={null}
					/>
				</div>
			</div>
		</>
	);
};
