import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userList } from '../../tempData';
import { ConnectAccount } from '../modal/ConnectAccount';
import { UserInfoModal } from '../modal/UserInfoModal';

export const UserList = () => {
	const navigate = useNavigate();
	const [visibleUserInfo, setVisibleUserInfo] = useState(false);
	const [visibleAccountInfo, setVisibleAccountInfo] = useState(false);
	return (
		<>
			<UserInfoModal visible={visibleUserInfo} setVisible={setVisibleUserInfo} />
			<ConnectAccount visible={visibleAccountInfo} setVisible={setVisibleAccountInfo} />

			<div className="table-container">
				<table className="table-style type2">
					<colgroup>
						<col width="50px" />
						<col width="250px" />
						<col width="300px" />
						<col width="250px" />
						<col width="250px" />
						<col width="150px" />
						<col width="350px" />
						<col width="250px" />
						<col width="250px" />
					</colgroup>
					<thead>
						<tr>
							<th className="first">
								<div className="chkbox-con">
									<input type="checkbox" />
								</div>
							</th>
							<th>wallet ID</th>
							<th>이메일 주소</th>
							<th>MATIC</th>
							<th>ITSB</th>
							<th>USDC</th>
							<th className="small center">
								최종접속
								<br />
								가입일
							</th>
							<th>Action</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{userList.map((i) => {
							return (
								<tr>
									<td>
										<div className="chkbox-con">
											<input type="checkbox" />
										</div>
									</td>

									<td>
										<p>{i.walletId}</p>
									</td>
									<td>{i.email}</td>
									<td>{i.matic}</td>
									<td>{i.itsb}</td>
									<td>{i.usdc}</td>
									<td className="center">
										{i.first}
										<br />
										{i.end}
									</td>
									<td>
										<div className="d-flex y-center">
											<div
												className="mr10"
												onClick={() => setVisibleAccountInfo(true)}
											>
												<img
													src={
														require('../../assets/img/chatbox-outline.svg')
															.default
													}
													alt="chat"
												/>
											</div>

											<div
												className=""
												onClick={() => setVisibleUserInfo(true)}
											>
												<img
													src={
														require('../../assets/img/person-outline.svg')
															.default
													}
													alt="user"
												/>
											</div>
										</div>
									</td>
									<td className="pointer" onClick={() => navigate('/userDetail')}>
										<img
											src={
												require('../../assets/img/ellipsis-vertical-outline.svg')
													.default
											}
											alt="dot"
										/>
									</td>
								</tr>
							);
						})}
						<tr></tr>
					</tbody>
				</table>
			</div>
		</>
	);
};
