export const Tag = ({ tagList, hasImg }) => {
	return (
		<div className="tag">
			{tagList.map((i) => {
				return (
					<div className={`tag-container`} key={Math.random() + 'tag'}>
						<div className="">
							<p className="tag-title">{i.title}</p>
							<p className="tag-number">
								{i.number} <span className="tag-number-desc">{i.numberDesc}</span>
							</p>
							<p className={`tag-status ${i.status}`}>
								{i.status === 'up' ? (
									<img
										src={require('../../assets/img/up.svg').default}
										alt={'up'}
									/>
								) : (
									<img
										src={require('../../assets/img/down.svg').default}
										alt={'down'}
									/>
								)}

								{i.updownNumber}
							</p>
						</div>

						{hasImg ? (
							<div className="group-img">
								<img
									src={
										tagList.groupType === '1'
											? require('../../assets/img/group1.svg').default
											: require('../../assets/img/group2.svg').default
									}
									alt={'group-img'}
								/>
							</div>
						) : (
							<></>
						)}
					</div>
				);
			})}
		</div>
	);
};
