export const PaymentDetail = () => {
	return (
		<div className="payment">
			<div className="search-container">
				<h3>결제내역 상세정보</h3>
			</div>

			<div className="payment-detail">
				<div className="payment-title-container">
					<h4 className="title">UI CRETIVE</h4>
					<h4 className="detail-id">결제내역 #5036</h4>
				</div>

				<div className="address-container">
					<p className="address">
						0x7712578c95ee6e55cb57cf6e44ab6a5590a9a2a9c9a24448b8da6bbdda58d51b
					</p>
					<div className="date-container">
						<p className="date-title">Due Date</p>
						<p className="date-text">25 Sep 2021</p>
					</div>
				</div>

				<div className="content">
					<p>
						Transaction Hash:
						0x7712578c95ee6e55cb57cf6e44ab6a5590a9a2a9c9a24448b8da6bbdda58d51b <br />
						<br />
						Status: Success
						<br />
						<br />
						Block: 31723132 3094892 Block Confirmations
						<br />
						<br />
						Timestamp: 77 days 3 hrs ago (Aug-10-2022 06:43:34 AM +UTC)
						<br />
						<br />
						From: 0x53f1da1bb22752654a00828f673072f8594d3310 <br />
						Interacted With (To): Contract 0xc2132d05d31c914a87c6611c10748aeb04b58e8f
						(Mapper: USDT Stablecoin) <br />
						Tokens Transferred
						<br />
						From 0x53f1da1bb22752654a00828f673072f8594d3310
						<br />
						To 0x4adc08ba890cf6b3156d231408753848f3a2ca21 <br />
						For 10.904596 ($10.90) (PoS) Tether... (USDT)
						<br />
						<br />
						Value : 0 MATIC ($0.00)
						<br />
						Transaction Fee: 0.001061820000778668 MATIC ($0.00)
						<br />
						Txn Type: 0 (Legacy)
						<br />
						MATIC Price: $0.94 / MATIC <br />
						Private Note: To access the Private Note feature, you must be Logged In
					</p>
				</div>

				<div className="note-text-container">
					<div className="note-text">
						<p>Note :</p>
					</div>
				</div>

				<div className="button-container edit" style={{ width: 86 }}>
					<input type="button" value={'Edit'} className="button-type3" />
				</div>
			</div>
		</div>
	);
};
