import { useState } from 'react';
import { filterList } from '../../tempData';
import { SelectFilter } from '../../components/common/SelectFilter';
import { OnebyoneModal } from '../../components/modal/OnebyoneModal';
import { DropDown } from '../../components/common/DropDown';
import ReactPaginate from 'react-paginate';
import { useContactHook } from '../../hook/useContactHook';
import { useEffect } from 'react';
import { tokenSliceStr } from '../../utils';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export const OneByOne = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [visible, setVisible] = useState(false);
	const [currentFilter, setCurrentFilter] = useState({ label: 'All', value: 'all' });
	const { getContactList } = useContactHook();
	const [contactData, setContactData] = useState({});
	const [page, setPage] = useState(searchParams.get('page') || 0);
	const [clickId, setClickId] = useState(-1);

	const getContactListFn = useCallback(async () => {
		const list = await getContactList({ page: page + 1, take: 10 });
		setContactData(list || {});
	}, [page]);

	useEffect(() => {
		setSearchParams({ page });
		getContactListFn();
	}, [page]);

	return (
		<>
			<div className="onebyone">
				<OnebyoneModal
					visible={visible}
					setVisible={setVisible}
					clickId={clickId}
					getContactListFn={getContactListFn}
				/>
				<div className="search-container no-title">
					<div className="search-wrap">
						<div className="search-input-container">
							<div className="d-flex y-center">
								<div className="dropdown-container">
									<DropDown />
								</div>
								<input
									type="text"
									className="input-style2"
									placeholder="Search Email"
								/>
							</div>
							<div className="search-icon">
								<img
									src={require('../../assets/img/search-outline.svg').default}
									alt="search"
								/>
							</div>
						</div>
						<SelectFilter
							filterList={filterList}
							currentFilter={currentFilter}
							setCurrentFilter={setCurrentFilter}
						/>
					</div>
				</div>

				<div className="table-container mb30">
					<table className="table-style">
						<colgroup>
							<col width="150px" />
							<col width="200px" />
							<col width="250px" />
							<col width="300px" />
							<col width="200px" />
						</colgroup>
						<thead>
							<tr>
								<th className="first">
									<div className="chkbox-con">
										<input type="checkbox" />
										<label type="checkbox"></label>
									</div>
								</th>
								<th>제목</th>
								<th>계정</th>
								<th>내용</th>
								<th>상태</th>
							</tr>
						</thead>
						<tbody>
							{contactData.items &&
								contactData.items.map((i) => {
									return (
										<tr key={i.id + 'row'}>
											<td>
												<div className="chkbox-con">
													<input type="checkbox" />
													<label type="checkbox"></label>
												</div>
											</td>
											<td>
												<p className="title">{i.subject}</p>
											</td>
											<td className="account">
												{tokenSliceStr(i.writeUser.walletAccount)}
											</td>
											<td>
												<p className="content">{i.content}</p>
											</td>
											<td>
												<div
													className="table-time"
													onClick={() => {
														setClickId(i.id);
														setVisible(true);
													}}
												>
													<p
														className={`answer ${
															i.contacts && i.contacts.length > 0
																? 'complete'
																: 'ing'
														}`}
													>
														{i.contacts && i.contacts.length > 0
															? '답변완료'
															: '답변대기'}
													</p>
													<p>
														{dayjs(i.createdAt).format(
															'YYYY-MM-DD HH:mm',
														)}
													</p>
												</div>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>

				<div className="paging-container">
					<ReactPaginate
						pageCount={contactData.total / 10}
						breakLabel="..."
						pageRangeDisplayed={10}
						renderOnZeroPageCount={null}
						onPageChange={({ selected }) => {
							setPage(selected);
						}}
						initialPage={page}
					/>
				</div>
			</div>
		</>
	);
};
