import { useLocation } from 'react-router-dom';

export const Header = () => {
	let location = useLocation();

	if (location.pathname === '/') return <></>;

	return (
		<div className="header-wrap">
			<div className="header-container">
				<div className="user-info">
					<p className="nickname">John Doe</p>
					<p className="auth">Admin</p>
				</div>
			</div>
		</div>
	);
};
