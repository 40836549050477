// dashboard
export const tagList = [
	{
		title: 'Total ITSB',
		number: '9.700',
		numberDesc: 'ITSB',
		status: 'up',
		updownNumber: '1.700',
	},
	{
		title: 'Total ITSB',
		number: '9.700',
		numberDesc: 'ITSB',
		status: 'down',
		updownNumber: '1.700',
	},
	{
		title: 'Total ITSB',
		number: '9.700',
		numberDesc: 'ITSB',
		status: 'up',
		updownNumber: '1.700',
	},
	{
		title: 'Total ITSB',
		number: '9.700',
		numberDesc: 'ITSB',
		status: 'down',
		updownNumber: '1.700',
	},
	{
		title: 'Total ITSB',
		number: '9.700',
		numberDesc: 'ITSB',
		status: 'up',
		updownNumber: '1.700',
	},
];

export const tagListWithImg = [
	{
		title: 'Total ITSB',
		number: '9.700',
		numberDesc: 'ITSB',
		status: 'up',
		updownNumber: '1.700',
		groupType: '1',
	},
	{
		title: 'Total ITSB',
		number: '9.700',
		numberDesc: 'ITSB',
		status: 'down',
		updownNumber: '1.700',
		groupType: '2',
	},
	{
		title: 'Total ITSB',
		number: '9.700',
		numberDesc: 'ITSB',
		status: 'up',
		updownNumber: '1.700',
		groupType: '2',
	},
];

export const barData = [
	{
		name: 'A1',
		Today: 2400,
	},
	{
		name: 'A2',
		Today: 1398,
	},
	{
		name: 'A3',
		Today: 9800,
	},
	{
		name: 'A4',
		Today: 3908,
	},
	{
		name: 'A5',
		Today: 4800,
	},
	{
		name: 'A6',
		Today: 3800,
	},
	{
		name: 'A7',
		Today: 4300,
	},

	{
		name: 'A8',
		Today: 4800,
	},
	{
		name: 'A9',
		Today: 3800,
	},
	{
		name: 'A10',
		Today: 4300,
	},
];

export const dChartData = [
	{ name: 'Group A', value: 400 },
	{ name: 'Group B', value: 300 },
	{ name: 'Group C', value: 300 },
	{ name: 'Group D', value: 200 },
];

export const historyList = [
	{
		type: 'green',
		id: '0xbf7d5..............30C0F1',
		date: '	1 Sep 2021',
		chainType: 'on',
		paymentStatus: 'Pay',
		total: '$3171',
	},
	{
		type: 'yellow',
		id: '0xbf7d5..............30C0F1',
		date: '	1 Sep 2021',
		chainType: 'off',
		paymentStatus: 'STAKING',
		total: '$3171',
	},
	{
		type: 'red',
		id: '0xbf7d5..............30C0F1',
		date: '	1 Sep 2021',
		chainType: 'full',
		paymentStatus: 'STAKING',
		total: '$3171',
	},
];

export const dashboardFilter = [
	{ label: 'on-chain', value: 'on' },
	{ label: 'on/off-chain', value: 'full' },
	{ label: 'off-chain', value: 'off' },
];

// onebyone
export const filterList = [
	{ label: 'All', value: 'all' },
	{ label: '답변완료', value: 'on' },
	{ label: '답변대기', value: 'off' },
];

export const noticeList = [
	{
		title: 'The Title Of A Note',
		desc: `
		To An English Person, It Will Seem Like Simplified English, As A Skeptical Cambridge Friend Of Mine Told Me What Occidental Is. The European Languages Are Members Of The Same Family. Their Separate Existence Is A Myth. 

For Science, Music, Sport, Etc, To Achieve This, It Would Be Necessary Separate Existence Is A Myth. If Several Languages Coalesce
		`,
		date: '1 Sep 2021',
		isOff: false,
	},

	{
		title: 'The Title Of A Note',
		desc: `
		To An English Person, It Will Seem Like Simplified English, As A Skeptical Cambridge Friend Of Mine Told Me What Occidental Is. The European Languages Are Members Of The Same Family. Their Separate Existence Is A Myth. 

For Science, Music, Sport, Etc, To Achieve This, It Would Be Necessary Separate Existence Is A Myth. If Several Languages Coalesce
		`,
		date: '1 Sep 2021',
		isOff: false,
	},

	{
		title: 'The Title Of A Note',
		desc: `
		To An English Person, It Will Seem Like Simplified English, As A Skeptical Cambridge Friend Of Mine Told Me What Occidental Is. The European Languages Are Members Of The Same Family. Their Separate Existence Is A Myth. 

For Science, Music, Sport, Etc, To Achieve This, It Would Be Necessary Separate Existence Is A Myth. If Several Languages Coalesce
		`,
		date: '1 Sep 2021',
		isOff: false,
	},

	{
		title: 'The Title Of A Note',
		desc: `
		To An English Person, It Will Seem Like Simplified English, As A Skeptical Cambridge Friend Of Mine Told Me What Occidental Is. The European Languages Are Members Of The Same Family. Their Separate Existence Is A Myth. 

For Science, Music, Sport, Etc, To Achieve This, It Would Be Necessary Separate Existence Is A Myth. If Several Languages Coalesce
		`,
		date: '1 Sep 2021',
		isOff: false,
	},

	{
		title: 'The Title Of A Note',
		desc: `
		To An English Person, It Will Seem Like Simplified English, As A Skeptical Cambridge Friend Of Mine Told Me What Occidental Is. The European Languages Are Members Of The Same Family. Their Separate Existence Is A Myth. 

For Science, Music, Sport, Etc, To Achieve This, It Would Be Necessary Separate Existence Is A Myth. If Several Languages Coalesce
		`,
		date: '1 Sep 2021',
		isOff: true,
	},

	{
		title: 'The Title Of A Note',
		desc: `
		To An English Person, It Will Seem Like Simplified English, As A Skeptical Cambridge Friend Of Mine Told Me What Occidental Is. The European Languages Are Members Of The Same Family. Their Separate Existence Is A Myth. 

For Science, Music, Sport, Etc, To Achieve This, It Would Be Necessary Separate Existence Is A Myth. If Several Languages Coalesce
		`,
		date: '1 Sep 2021',
		isOff: true,
	},
];

// user
export const userList = [
	{
		walletId: '0xbf7d5..............30C0F1',
		email: '14507471j@gmail.com',
		matic: '2,000,000',
		itsb: '2,000,000',
		usdc: '2,000,000',
		first: '22-10-30',
		end: '22-10-30',
	},

	{
		walletId: '0xbf7d5..............30C0F1',
		email: '14507471j@gmail.com',
		matic: '2,000,000',
		itsb: '2,000,000',
		usdc: '2,000,000',
		first: '22-10-30',
		end: '22-10-30',
	},
];
