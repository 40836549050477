import { useState } from 'react';
import { DropDown } from '../../components/common/DropDown';
import { SelectFilter } from '../../components/common/SelectFilter';
import { PaymentList } from '../../components/payment/PaymentList';
import { dashboardFilter } from '../../tempData';

export const Payment = () => {
	const [currentFilter, setCurrentFilter] = useState({ label: 'on-chain', value: 'on' });
	return (
		<div className="payment">
			<div className="search-container no-margin">
				<h3>결제내역</h3>
				<div className="search-wrap">
					<div className="search-input-container">
						<input type="text" className="input-style2" placeholder="Search Notes" />
						<div className="search-icon">
							<img
								src={require('../../assets/img/search-outline.svg').default}
								alt="search"
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="d-flex x-eq mb30 mt30">
				<div className="">
					<SelectFilter
						filterList={dashboardFilter}
						currentFilter={currentFilter}
						setCurrentFilter={setCurrentFilter}
					/>
				</div>

				<div className="d-flex">
					<div className="mr10">
						<DropDown />
					</div>
					<DropDown />
				</div>
			</div>
			<PaymentList />
		</div>
	);
};
