import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useContactHook } from '../../hook/useContactHook';
import { tokenSliceStr } from '../../utils';

export const OnebyoneModal = ({ visible, setVisible, clickId, getContactListFn }) => {
	const { getContactDetail, createContactAnswer, updateContactAnswer } = useContactHook();
	const [isUpdate, setIsUpdate] = useState(false);
	const [detail, setDetail] = useState({});
	const [subject, setSubject] = useState('');
	const [content, setContent] = useState('');
	const close = () => {
		setVisible(false);
	};

	const getDetail = useCallback(async () => {
		const item = await getContactDetail({ clickId });
		setDetail(item);
		if (item.contacts.length > 0) {
			setIsUpdate(true);
			setSubject(item.contacts[0].subject);
			setContent(item.contacts[0].content);
		} else {
			setIsUpdate(false);
			setSubject('');
			setContent('');
		}
	}, [clickId]);

	useEffect(() => {
		if (clickId !== -1) getDetail();
	}, [clickId, visible]);

	const saveAnswer = async () => {
		let msg;
		try {
			if (isUpdate) {
				const { message } = await updateContactAnswer({
					subject,
					content,
					id: detail.contacts[0].id,
				});
				msg = message;
			} else {
				const { message } = await createContactAnswer({ subject, content, id: detail.id });
				msg = message;
			}
			alert(msg);
		} catch (err) {
			alert(err);
		}
		await getContactListFn();
		await getDetail();
	};

	if (!visible) return <></>;
	return (
		<div className="modal-wrap">
			<div className="dim" />
			<div className="modal-container">
				<div className="modal-header">
					<p className="modal-title">1:1문의</p>
					<div className="header-close">
						<div onClick={close}>
							<img
								src={require('../../assets/img/close-outline.svg').default}
								alt="pen"
							/>
						</div>
					</div>
				</div>

				<div className="modal-content">
					<div className="modal-account-container">
						<p className="acconut-title">계정</p>
						<p className="account-content">
							{detail.writeUser && tokenSliceStr(detail.writeUser.walletAccount)}
						</p>
					</div>

					<div className="modal-question-container mb10">
						<div className="row line">
							<p className="row-title">제목</p>
							<p className="row-content">{detail.subject}</p>
						</div>

						<div className="row">
							<p className="row-title">내용</p>
							<p className="row-content gray">{detail.content}</p>
						</div>
					</div>

					<div className="modal-question-container">
						<div className="row line">
							<p className="row-title">제목</p>
							<input
								value={subject}
								type="text"
								className="input-style3"
								placeholder="제목"
								onChange={(e) => {
									setSubject(e.target.value);
								}}
							/>
						</div>

						<div className="row">
							<p className="row-title">내용</p>
							<textarea
								value={content}
								resize={false}
								className="textarea-style1"
								placeholder="내용"
								onChange={(e) => {
									setContent(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>

				<div className="modal-bottom">
					<div className="cancel-button" onClick={close}>
						<img
							src={require('../../assets/img/close-outline-black.svg').default}
							alt="x"
						/>
						<p>Cancel</p>
					</div>
					<div className="button-container" style={{ width: 153 }}>
						<input
							type="button"
							value={'Save'}
							className="button-type2"
							onClick={saveAnswer}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
