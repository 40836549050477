import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { dChartData } from '../../tempData';

const data = [
	{ name: 'Group A', value: 400 },
	{ name: 'Group B', value: 300 },
	{ name: 'Group C', value: 300 },
	{ name: 'Group D', value: 200 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const Dchart = () => {
	return (
		<div className="dchart-container">
			<PieChart width={500} height={600}>
				<Pie
					data={dChartData}
					cx={300}
					cy={200}
					innerRadius={180}
					fill="#8884d8"
					paddingAngle={10}
					dataKey="value"
				>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
			</PieChart>
		</div>
	);
};
