import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store';
import { Spinner } from './components/layout/Spinner';
const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Spinner />
				<App />
			</PersistGate>
		</Provider>
	</React.StrictMode>,
);
