import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSignHook } from '../../hook/useSignHook';

export const Gnb = () => {
	const navigate = useNavigate();
	let location = useLocation();
	const { signOut } = useSignHook();

	const logout = () => {
		signOut();
		navigate('/');
	};

	if (location.pathname === '/') return <></>;
	return (
		<div className="gnb-wrap">
			<ul>
				<li className={location.pathname === '/dashboard' ? 'active' : ''}>
					<Link to="/dashboard">
						<div className="gnb-item">
							<div className="gnb-img-container">
								<img
									src={require('../../assets/img/dash.svg').default}
									alt={'dashboard'}
								></img>
							</div>

							<p>Dashboard</p>
						</div>
					</Link>
				</li>
				<li
					className={
						location.pathname === '/user' || location.pathname === '/userDetail'
							? 'active'
							: ''
					}
				>
					<Link to="/user">
						<div className="gnb-item">
							<div className="gnb-img-container">
								<img
									src={require('../../assets/img/user.svg').default}
									alt={'user'}
								></img>
							</div>
							<p>유저리스트</p>
						</div>
					</Link>
				</li>
				<li
					className={
						location.pathname === '/payment' || location.pathname === '/paymentDetail'
							? 'active'
							: ''
					}
				>
					<Link to="/payment">
						<div className="gnb-item">
							<div className="gnb-img-container">
								<img
									src={require('../../assets/img/payment.svg').default}
									alt={'user'}
								></img>
							</div>
							<p>결제내역</p>
						</div>
					</Link>
				</li>
				<li className={location.pathname === '/notice' ? 'active' : ''}>
					<Link to="/notice">
						<div className="gnb-item">
							<div className="gnb-img-container">
								<img
									src={require('../../assets/img/document.svg').default}
									alt={'user'}
								></img>
							</div>
							<p>공지사항</p>
						</div>
					</Link>
				</li>
				<li className={location.pathname === '/onebyone' ? 'active' : ''}>
					<Link to="/onebyone">
						<div className="gnb-item">
							<div className="gnb-img-container">
								<img
									src={require('../../assets/img/document.svg').default}
									alt={'user'}
								></img>
							</div>
							<p>1:1문의내역</p>
						</div>
					</Link>
				</li>
				<li onClick={logout}>
					<div className="gnb-item">
						<div className="gnb-img-container"></div>
						<p>로그아웃</p>
					</div>
				</li>
			</ul>
		</div>
	);
};
