import api from '../api';

export const useNoticeHook = () => {
	const getNoticeList = async ({ page, take = 10 }) => {
		try {
			const { data } = await api.get(`/api/admin/notice?page=${page}&take=${take}`);
			console.log(data);
			return data;
		} catch (err) {
			console.log(err);
		}
	};

	const getNoticeDetail = async ({ clickId }) => {
		try {
			const { data } = await api.get(`/api/admin/notice/find/${clickId}`);
			return data;
		} catch (err) {
			console.log(err);
		}
	};

	const createNotice = async ({ content, subject }) => {
		try {
			const { data } = await api.post('/api/admin/notice', {
				content,
				subject,
			});
			return data;
		} catch (err) {
			console.log(err);
		}
	};

	const updateNotice = async ({ content, subject, isImportant, id }) => {
		try {
			const { data } = await api.patch('/api/admin/notice', {
				id,
				content,
				subject,
				isImportant,
			});
			return data;
		} catch (err) {
			console.log(err);
		}
	};

	const deleteNotice = async ({ id }) => {
		console.log(id);
		try {
			const { data } = await api.delete('/api/admin/notice', {
				data: { id },
			});
			return data;
		} catch (err) {
			console.log(err);
		}
	};

	return { getNoticeList, getNoticeDetail, createNotice, updateNotice, deleteNotice };
};
