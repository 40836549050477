import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { DropDown } from '../../components/common/DropDown';
import { CreateNoticeModal } from '../../components/modal/CreateNoticeModal';
import { NoticeDetailModal } from '../../components/modal/NoticeDetailModal';
import { NoticeItem } from '../../components/notice/NoticeItem';
import { useNoticeHook } from '../../hook/useNoticeHook';
import { noticeList } from '../../tempData';

export const Notice = () => {
	const [visibleCreateModal, setVisibleCreateModal] = useState(false);
	const [visibleDetailModal, setVisibleDetailModal] = useState(false);
	const [currentFilter, setCurrentFilter] = useState({ label: 'All', value: 'all' });
	const { getNoticeList } = useNoticeHook();
	const [noticeData, setNoticeData] = useState({});
	const [page, setPage] = useState(1);
	const [clickId, setClickId] = useState(-1);

	const getNoticeListFn = useCallback(async () => {
		const list = await getNoticeList({ page: page, take: 10 });
		setNoticeData(list || {});
	}, [page]);

	useEffect(() => {
		getNoticeListFn();
	}, [page]);

	return (
		<>
			<CreateNoticeModal
				visible={visibleCreateModal}
				setVisible={setVisibleCreateModal}
				getNoticeListFn={getNoticeListFn}
			/>
			<NoticeDetailModal
				clickId={clickId}
				visible={visibleDetailModal}
				setVisible={setVisibleDetailModal}
				getNoticeListFn={getNoticeListFn}
				setClickId={setClickId}
			/>
			<div className="notice">
				<div className="search-container">
					<h3>공지사항</h3>
					<div className="search-wrap">
						<div className="dropdown-container ml50">
							<DropDown />
						</div>
						<div className="search-input-container">
							<input
								type="text"
								className="input-style2"
								placeholder="Search Notes"
							/>
						</div>
					</div>
					<div
						className="button-container"
						style={{ width: 153 }}
						onClick={() => setVisibleCreateModal(true)}
					>
						<input type="button" value={'Add'} className="button-type2" />
					</div>
				</div>

				<div className="notice-content">
					{noticeData.items &&
						noticeData.items.map((i) => (
							<NoticeItem
								item={i}
								setClickId={setClickId}
								setVisibleDetailModal={setVisibleDetailModal}
							/>
						))}
				</div>
			</div>
		</>
	);
};
