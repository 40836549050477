import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { useState } from 'react';
import { useNoticeHook } from '../../hook/useNoticeHook';

export const CreateNoticeModal = ({ visible, setVisible, getNoticeListFn }) => {
	const { createNotice } = useNoticeHook();
	const [content, setContent] = useState('');
	const [subject, setSubject] = useState('');

	const create = async () => {
		try {
			await createNotice({ content, subject });
			alert('정상 등록 되었습니다.');
		} catch (err) {
			alert('에러가 발생 했습니다.');
		}
		await getNoticeListFn();
		close();
	};

	const close = () => {
		setVisible(false);
		setContent('');
		setSubject('');
	};

	if (!visible) return <></>;
	return (
		<div className="modal-wrap">
			<div className="dim" />
			<div className="modal-container">
				<div className="modal-header">
					<p className="modal-title">New Notes</p>
					<div className="header-close">
						<div onClick={close}>
							<img
								src={require('../../assets/img/close-outline.svg').default}
								alt="pen"
							/>
						</div>
					</div>
				</div>

				<div className="modal-content">
					<div className="modal-account-container">
						<p className="acconut-title">Title</p>
						<input
							type="text"
							className="input-style1"
							placeholder="Title"
							onChange={(e) => {
								setSubject(e.target.value);
							}}
						/>
					</div>
					<ReactQuill theme="snow" value={content} onChange={setContent} height={300} />
				</div>

				<div className="modal-bottom">
					<div className="cancel-button" onClick={close}>
						<img
							src={require('../../assets/img/close-outline-black.svg').default}
							alt="x"
						/>
						<p>Cancel</p>
					</div>
					<div className="button-container" style={{ width: 153 }} onClick={create}>
						<input type="button" value={'Save'} className="button-type2" />
					</div>
				</div>
			</div>
		</div>
	);
};
