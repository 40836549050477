import axios from 'axios';
import { addAsyncCountValue, minusAsyncCountValue } from '../store/spinner-store';
import store from '../store';

const { dispatch } = store;
const Axios = axios.create({
	baseURL: 'https://api.valable.io',
	timeout: 5000,
	withCredentials: false,
	headers: {
		'X-VALABLE-VERSION': '1',
	},
});

Axios.interceptors.request.use(
	(config) => {
		dispatch(addAsyncCountValue());
		config.headers.authorization = `Bearer ${store.getState().userinfo.accessToken}`;
		return config;
	},
	(error) => Promise.reject(error),
);

Axios.interceptors.response.use(
	(response) => {
		dispatch(minusAsyncCountValue());
		return response;
	},
	(error) => {
		dispatch(minusAsyncCountValue());
		if (error.response.data.statusCode === 401) {
			window.location.href = '/';
		}
		return Promise.reject(error);
	},
);

export default Axios;
