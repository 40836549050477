import { createSlice } from '@reduxjs/toolkit';

const { name, reducer, actions } = createSlice({
	name: 'userinfo',
	initialState: {
		loginStatus: '',
		accessToken: '',
	},
	reducers: {
		loginProcess(state, { payload }) {
			console.log(payload);
			return { ...state, loginStatus: payload.loginStatus, accessToken: payload.accessToken };
		},
		logoutProcess(state) {
			return {
				...state,
				loginStatus: '',
				accessToken: '',
			};
		},
	},
});

const { loginProcess, logoutProcess } = actions;
export default reducer;
export { name, loginProcess, logoutProcess };
