import React from 'react';
import { useSelector } from 'react-redux';
import { spinnerName } from '../../store';

export const Spinner = () => {
	const { count } = useSelector((state) => state[spinnerName]);
	if (count > 0) {
		return (
			<div
				style={{
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					position: 'fixed',
					width: '100%',
					height: '100vh',
					zIndex: 10000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div class="lds-roller">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}
	return null;
};
