import ReactPaginate from 'react-paginate';

export const OnebyoneTable = () => {
	return (
		<div className="content-container">
			<h3>1:1 문의</h3>

			<div className="mw-750 center">
				<table className="table-style style2">
					<colgroup>
						<col width="250px" />
						<col width="250px" />
						<col width="200px" />
						<col width="250px" />
					</colgroup>
					<thead>
						<tr>
							<th>날짜</th>
							<th>문의 내용</th>
							<th>닉네임</th>
							<th>연동주소</th>
						</tr>
						<tr className="border">
							<th></th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr className="empty">
							<td />
							<td />
							<td />
							<td />
						</tr>
						<tr>
							<td>DK모바일</td>
							<td>14507471j@gmail.com</td>
							<td>baskin</td>
							<td>0xbf7d5..............30C0F1</td>
						</tr>

						<tr>
							<td>DK모바일</td>
							<td>14507471j@gmail.com</td>
							<td>baskin</td>
							<td>0xbf7d5..............30C0F1</td>
						</tr>
					</tbody>
				</table>

				<div className="paging-container x-end mt30">
					<ReactPaginate
						pageCount={100}
						breakLabel="..."
						pageRangeDisplayed={5}
						renderOnZeroPageCount={null}
					/>
				</div>
			</div>
		</div>
	);
};
