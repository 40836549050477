import api from '../api';
import Cookies from 'universal-cookie';
import store from '../store';
import { loginProcess, logoutProcess } from '../store/userinfo-store';

export const useSignHook = () => {
	const { dispatch } = store;

	const saveId = (id) => {
		const cookies = new Cookies();
		cookies.set('saveId', id, { path: '/' });
	};

	const signOut = () => {
		dispatch(logoutProcess());
	};

	const signIn = async ({ id, password, isSaveId }) => {
		if (isSaveId) saveId(id);
		try {
			const {
				data: { accessToken },
			} = await api.post('/api/auth/admin/login', {
				id,
				signPassword: password,
			});

			dispatch(
				loginProcess({
					loginStatus: 'login',
					accessToken,
				}),
			);
		} catch (err) {
			alert('아이디 및 비밀번호를 확인해주세요.');
			throw err;
		}
	};

	return {
		signIn,
		signOut,
	};
};
