export const SelectFilter = ({ filterList, currentFilter, setCurrentFilter }) => {
	return (
		<div className="select-filter">
			{filterList.map((i) => {
				return (
					<div
						key={Math.random() + 'filter'}
						onClick={() => setCurrentFilter(i)}
						className={`filter-item ${i.value === currentFilter.value ? 'active' : ''}`}
					>
						{i.label}
					</div>
				);
			})}
		</div>
	);
};
