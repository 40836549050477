import { OnebyoneTable } from '../../components/userDetail/OnebyoneTable';

export const UserDetail = () => {
	return (
		<div className="user">
			<div className="search-container">
				<h3>유저리스트 </h3>
				<div className="button-container" style={{ width: 153 }}>
					<input type="button" value={'Save'} className="button-type2" />
				</div>
			</div>

			<div className="user-detail-container">
				<div className="detail-gnb">
					<div className="left-line"></div>
					<img
						src={require('../../assets/img/person-outline-purple.svg').default}
						alt=""
					/>
					<p>Contact</p>
				</div>

				<div className="content">
					<div className="user-info mb30">
						<div className="row">
							<div className="row-title">
								<p>wallet ID</p>
							</div>
							<div className="row-detail">
								<p>0xbf7d5..............30C0F1</p>
							</div>
						</div>

						<div className="row">
							<div className="row-title">
								<p>이메일주소 : </p>
							</div>
							<div className="row-detail width100">
								<p>
									<input
										className="input-style1"
										placeholder="14507471@gmail.com"
									></input>
								</p>
							</div>
						</div>

						<div className="row">
							<div className="row-title">
								<p>연결된 게임/계정ID</p>
							</div>
							<div className="row-detail">
								<div className="">
									<div className="chkbox-con type2">
										<input type="checkbox" />
										<label type="checkbox">DK</label>
									</div>

									<div className="chkbox-con type2">
										<input type="checkbox" />
										<label type="checkbox">COC</label>
									</div>

									<div className="chkbox-con">
										<input type="checkbox" />
										<label type="checkbox">ALLSTAR</label>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="row-title">
								<p>가입한 날짜:</p>
							</div>
							<div className="row-detail">
								<p>22_10_30</p>
							</div>
						</div>
					</div>

					<div className="mb60">
						<OnebyoneTable />
					</div>

					<div className="">
						<OnebyoneTable />
					</div>
				</div>
			</div>
		</div>
	);
};
