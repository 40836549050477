import { useState } from 'react';
import { SelectFilter } from '../../components/common/SelectFilter';
import { Barchart } from '../../components/dashboard/Barchart';
import { Dchart } from '../../components/dashboard/Dchart';
import { History } from '../../components/dashboard/History';
import { Tag } from '../../components/dashboard/Tag';
import { dashboardFilter, tagList, tagListWithImg } from '../../tempData';

export const Dashboard = () => {
	const [currentFilter, setCurrentFilter] = useState({ label: 'on-chain', value: 'on' });

	return (
		<div className="dashboard">
			<Tag tagList={tagList} />

			<div className="chart-container">
				<Barchart />
				<Dchart />
			</div>

			<Tag tagList={tagListWithImg} hasImg />

			<div className="history-container">
				<h3>히스토리</h3>
				<div className="dashboard-filter">
					<SelectFilter
						filterList={dashboardFilter}
						currentFilter={currentFilter}
						setCurrentFilter={setCurrentFilter}
					/>
				</div>

				<History />
			</div>
		</div>
	);
};
