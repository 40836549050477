import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AxiosInterceptor } from '../api';
import { Gnb } from '../components/layout/Gnb';
import { Header } from '../components/layout/Header';
import { Dashboard } from '../pages/dashboard';
import { Login } from '../pages/login';
import { Notice } from '../pages/notice';
import { OneByOne } from '../pages/onebyone';
import { Payment } from '../pages/payment';
import { PaymentDetail } from '../pages/payment-detail';
import { User } from '../pages/user';
import { UserDetail } from '../pages/user-detail';

export const Router = () => {
	return (
		<BrowserRouter>
			<div className="App">
				<Gnb />
				<div className="main">
					<Header />
					<Routes>
						<Route path="/" element={<Login />}></Route>
						<Route path="/dashboard" element={<Dashboard />}></Route>
						<Route path="/onebyone" element={<OneByOne />}></Route>
						<Route path="/notice" element={<Notice />}></Route>
						<Route path="/payment" element={<Payment />}></Route>
						<Route path="/paymentDetail" element={<PaymentDetail />}></Route>
						<Route path="/user" element={<User />}></Route>
						<Route path="/userDetail" element={<UserDetail />}></Route>
					</Routes>
				</div>
			</div>
		</BrowserRouter>
	);
};
