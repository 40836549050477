import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '../../components/common/Checkbox';
import { useSignHook } from '../../hook/useSignHook';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const Login = () => {
	const loginStatus = useSelector((state) => state.userinfo.loginStatus);
	const [isSaveId, setIsSaveId] = useState(false);
	const [id, setId] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();
	const { signIn } = useSignHook();

	const login = async () => {
		try {
			await signIn({ id, password, isSaveId });
			navigate('/dashboard');
		} catch (err) {}
	};

	useEffect(() => {
		if (loginStatus === 'login') {
			navigate('/dashboard');
		}
		const cookies = new Cookies();
		const saveIdCookie = cookies.get('saveId');
		console.log(saveIdCookie);
		if (saveIdCookie) {
			setIsSaveId(true);
			setId(saveIdCookie);
		}
	}, []);

	return (
		<form className="login-wrap">
			<div className="login-container">
				<h3 className="login-title">Login To Your Account</h3>
				<div className="login-input-container">
					<input
						value={id}
						type="email"
						className="input-style1"
						placeholder="Email"
						onChange={(e) => {
							setId(e.target.value);
						}}
					/>
				</div>
				<div className="login-input-container">
					<input
						type="password"
						className="input-style1"
						placeholder="Password"
						onChange={(e) => {
							setPassword(e.target.value);
						}}
					/>
				</div>

				<div className="login-checkbox-container">
					<Checkbox text={'Remember Me'} check={isSaveId} setCheck={setIsSaveId} />
				</div>

				<div className="button-container" onClick={login}>
					<input type="button" value={'Login'} className="button-type1" />
				</div>
			</div>
		</form>
	);
};
